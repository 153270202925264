<template>
  <div class="planData">
     <div class="box">
       <p>处方数据</p>
       <el-input class="sro" v-model="cfdata" placeholder="请输入内容"></el-input>
       <el-button>默认按钮</el-button>
     </div>
  </div>
</template>

<script>
export default {
  name: "planData",
  data() {
    return {
      cfdata: ''
    }
  }
}
</script>

<style scoped lang="scss">
  .planData {
    width: 90%;
    margin: 0 auto;
    padding-top: 0.1rem;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: start;
    .sro {
      width: 10vw;
      margin: 0 20px;
    }
  }
</style>